.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 15;
}


.modal.enter-done{
    opacity: 1;
    pointer-events: visible;
}

.modal.exit{
    opacity: 0;
}

.modal-content {
    max-width: 100%;
    width: 600px;
    background-color: #FFF;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

@media(max-height:740px){
    .modal-content{
        height: 600px;
        overflow: auto;
    }
}

.modal.enter-done .modal-content{
    transform: translateY(0);
}

.modal.exit .modal-content{
    transform: translateY(-200px);
}

.modal-header, .modal-footer{
    padding: 10px;
}

.logo-title{
    width: 80px;
    padding-left: 10px;
}

.modal-header{
    background-color: black;
    display: flex;
    justify-content: space-between;
}

.button{
    border-style: none;
    padding: 0 10px;
    font-size: 30px;
    color:white;
    
}

.modal-footer button{
    border-style: none;
    background-color: black;
    color: white;
    padding: 5px 15px;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 5px;
    float: right;
    margin: 10px;
}

.modal-footer button:hover{
    transition-duration: 0.5s;
    opacity: 0.5;
}

.modal-title {
    margin: 0;
}

.modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    border-top: 1px solid #eee;
}

.modal-body p{
    color:black;
    text-align: center;
}

.modal-body h5{
    color: black;
    font-weight: 600;
    text-align: center;
}

.input-container{
    max-width: 100%;
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding: 0 15px;
}

.input-class{
    padding: 10px 15px;
    font-size: 14px;
    margin-bottom: 15px;
    border: 1px solid black;
    
}

