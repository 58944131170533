@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li { 
        letter-spacing: 2px;
        font-family: Roboto Condensed, sans-serif;
        color: #999999;
        
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Roboto Condensed, sans-serif;
    
}

*{
    box-sizing: border-box;
}